import React from "react";
import { Helmet } from "react-helmet";
import Button from "../components/atoms/buttons/button";
import Navi from "../components/navi";

const Page404 = ({ location }) => {
   const homepageLink = {
      blok: {
         button_color: "blue",
         title: "Retourner à la page d'accueil",
         link: {
            linktype: "story",
            cached_url: "",
         },
      },
   };

   return (
      <>
         <Helmet
            title="Page non trouvée"
            meta={[
               {
                  name: "robots",
                  content: `noindex, nofollow`,
               },
            ]}
         />

         <div className="mt-10 pt-20 md:pt-24 flex items-center max-w-full">
            <div className="relative bg-gray-50 max-w-full w-full md:overflow-hidden" data-width="full-width">
               <div className="relative z-20 my-10 mx-auto px-4 sm:mt-12 sm:px-6 md:my-16 lg:my-20 xl:my-28">
                  <div className="text-center">
                     <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none xl:text-6xl max-w-21ch mx-auto">Page 404</h1>

                     <div className="prose mx-auto text-center text-lg text-gray-600 sm:text-xl md:max-w-3xl mt-4 leading-8">
                        <p>La page demandée n’existe pas / plus. Celle-ci a peut-être été renommée ou déplacée. Veuillez nous excuser pour ce désagrément.</p>
                     </div>

                     <div className="mt-10 sm:flex sm:justify-center lg:items-center space-y-3 md:space-y-0 md:space-x-3 mx-auto text-center">
                        <Button {...homepageLink} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Page404;
